<template>
  <div
    class="popup_wrap"
    style="width:1300px; height:600px; overflow-x: hidden;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONEX010P090.001') }}</h1> <!-- B/L Log C/A 상세 -->
      <div class="content_box">
        <!-- content_box -->
        <table class="tbl_search">
          <colgroup>
            <col style="width:70px">
            <col style="width:150px">
            <col style="width:20px">
            <col style="width:70px">
            <col style="width:20px">
            <col style="width:60px">
            <col style="width:20px">
            <col style="width:60px">
          </colgroup>
          <tbody>
            <tr>
              <th>B/L No.</th>
              <td>
                <span>{{ blNo }}</span>
                <select class="wid150 ml20" v-model="selectedCaNo" @change="changeCa">
                  <option value="">select</option>
                  <template v-for="(ca, index) in mainCAHistoryList">
                    <option v-if="!isFlag || (isFlag && ca.caNo == selectedCaNo)" :key="index" :value="ca.caNo">{{ ca.reqDt }}({{ ca.caCatNm }})</option>
                  </template>
                </select>
              </td>
              <th v-show="polCtrCd === 'VN'">Date</th>
              <td v-show="polCtrCd === 'VN'">
                <input
                  type="text"
                  disabled
                  v-model="reqDt"
                >
              </td>
              <th v-show="polCtrCd === 'VN'">Time</th>
              <td v-show="polCtrCd === 'VN'">
                <input
                  type="text"
                  disabled
                  v-model="reqTm"
                >
              </td>
              <th v-show="polCtrCd === 'VN'">Action</th>
              <td v-show="polCtrCd === 'VN'">
                <input
                  type="text"
                  disabled
                  v-model="apvStsCd"
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div class="content_box mt10" v-if="$ekmtcCommon.isNotEmpty(selectedCaNo)">
        <!-- content_box -->
        <template v-for="(component, index) in componentName">
          <component :is="component" :main-data="mainData" :key="index"></component>
        </template>
      </div>
    </div><!-- popup_cont -->
  </div>
</template>

<script>
import shippingLog from '@/api/rest/trans/shippingLog'
import commons from '@/api/services/commons'
import ca from '@/api/rest/trans/ca'

export default {
  name: 'ShippingLogBLDetailPop',
  components: {
    'shipping-log-bl-detail-bl-body-pop': () => import('@/pages/trans/popup/ShippingLogBLDetailBLBodyPop'),
    'shipping-log-bl-detail-bl-container-pop': () => import('@/pages/trans/popup/ShippingLogBLDetailBLContainerPop'),
    'shipping-log-bl-detail-bl-freight-pop': () => import('@/pages/trans/popup/ShippingLogBLDetailBLFreightPop'),
    'shipping-log-bl-detail-header-pop': () => import('@/pages/trans/popup/ShippingLogBLDetailHeaderPop'),
    'shipping-log-bl-detail-leg-vessel-pop': () => import('@/pages/trans/popup/ShippingLogBLDetailLegVesselPop'),
    'shipping-log-bl-detail-bl-el-pop': () => import('@/pages/trans/popup/ShippingLogBLDetailBLElPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          caNo: '',
          isFlag: false,
          polCtrCd: ''
        }
      }
    }
  },
  data () {
    return {
      blNo: this.parentInfo.blNo,
      isFlag: this.parentInfo.isFlag,
      selectedCaNo: (this.parentInfo.caNo || ''),
      mainCAHistoryList: '',
      items: [],
      kinds: {},
      componentName: [],
      caFlag: this.parentInfo.propCaFlag,
      mainData: {},
      polCtrCd: this.parentInfo.polCtrCd,
      apvStsCdList: [],
      reqDt: '',
      reqTm: '',
      apvStsCd: ''
    }
  },
  created: function () {
  },
  mounted: function () {
    this.init().then(() => {
      this.loadComponent()
    })
  },
  methods: {
    loadComponent () {
      this.componentName = ['shipping-log-bl-detail-bl-body-pop',
                            'shipping-log-bl-detail-leg-vessel-pop',
                            'shipping-log-bl-detail-bl-freight-pop',
                            'shipping-log-bl-detail-header-pop',
                            'shipping-log-bl-detail-bl-container-pop',
                            'shipping-log-bl-detail-bl-el-pop'
                          ]
    },
    async init () {
      this.mainData = {}
      const mainData = await shippingLog.getShippingLogBlDetail({ blNo: this.parentInfo.blNo, caNo: this.selectedCaNo })
      this.mainData = { ...mainData.data }
      this.$set(this.mainData, 'changeFlag', 'Y')
      this.mainCAHistoryList = this.mainData.mainCAHistoryList

      const arrCdId = ['01230']
      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        this.apvStsCdList = res['01230']
      })
    },
    changeCa () {
      if (this.$ekmtcCommon.isNotEmpty(this.selectedCaNo)) {
        //this.componentName = []
        this.mainCAHistoryList.forEach(ca => {
          if (ca.caNo === this.selectedCaNo) {
            this.reqDt = moment(ca.reqDt).format('YYYY-MM-DD')
            this.reqTm = this.$ekmtcCommon.changeTimePattern(ca.reqTm)
            this.apvStsCd = this.getApvStsCdNm(ca.apvStsCd)
            return false
          }
        })

        this.init().then(() => {
          this.loadComponent()
        })
      }
    },
    getApvStsCdNm (cd) {
      let cdNm = ''
      this.apvStsCdList.forEach(value => {
        if (value.cd === cd) {
          cdNm = value.cdNm
          return false
        }
      })

      return cdNm
    }

  }
}
</script>
