var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1300px", height: "600px", "overflow-x": "hidden" },
    },
    [
      _c("button", {
        staticClass: "layer_close",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_search" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("B/L No.")]),
                _c("td", [
                  _c("span", [_vm._v(_vm._s(_vm.blNo))]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedCaNo,
                          expression: "selectedCaNo",
                        },
                      ],
                      staticClass: "wid150 ml20",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedCaNo = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.changeCa,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("select"),
                      ]),
                      _vm._l(_vm.mainCAHistoryList, function (ca, index) {
                        return [
                          !_vm.isFlag ||
                          (_vm.isFlag && ca.caNo == _vm.selectedCaNo)
                            ? _c(
                                "option",
                                { key: index, domProps: { value: ca.caNo } },
                                [
                                  _vm._v(
                                    _vm._s(ca.reqDt) +
                                      "(" +
                                      _vm._s(ca.caCatNm) +
                                      ")"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.polCtrCd === "VN",
                        expression: "polCtrCd === 'VN'",
                      },
                    ],
                  },
                  [_vm._v("Date")]
                ),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.polCtrCd === "VN",
                        expression: "polCtrCd === 'VN'",
                      },
                    ],
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reqDt,
                          expression: "reqDt",
                        },
                      ],
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.reqDt },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.reqDt = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.polCtrCd === "VN",
                        expression: "polCtrCd === 'VN'",
                      },
                    ],
                  },
                  [_vm._v("Time")]
                ),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.polCtrCd === "VN",
                        expression: "polCtrCd === 'VN'",
                      },
                    ],
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reqTm,
                          expression: "reqTm",
                        },
                      ],
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.reqTm },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.reqTm = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.polCtrCd === "VN",
                        expression: "polCtrCd === 'VN'",
                      },
                    ],
                  },
                  [_vm._v("Action")]
                ),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.polCtrCd === "VN",
                        expression: "polCtrCd === 'VN'",
                      },
                    ],
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.apvStsCd,
                          expression: "apvStsCd",
                        },
                      ],
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.apvStsCd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.apvStsCd = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm.$ekmtcCommon.isNotEmpty(_vm.selectedCaNo)
          ? _c(
              "div",
              { staticClass: "content_box mt10" },
              [
                _vm._l(_vm.componentName, function (component, index) {
                  return [
                    _c(component, {
                      key: index,
                      tag: "component",
                      attrs: { "main-data": _vm.mainData },
                    }),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "70px" } }),
      _c("col", { staticStyle: { width: "150px" } }),
      _c("col", { staticStyle: { width: "20px" } }),
      _c("col", { staticStyle: { width: "70px" } }),
      _c("col", { staticStyle: { width: "20px" } }),
      _c("col", { staticStyle: { width: "60px" } }),
      _c("col", { staticStyle: { width: "20px" } }),
      _c("col", { staticStyle: { width: "60px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }